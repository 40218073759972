import React from "react";

class Marquee extends React.Component {

    render() {
        return(

            <div className="main-container">

                <div className="marquee-container" role="marquee">
                    <h4 className="marquee-text">Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • Work Samples Available • </h4>
                </div>

            </div>

        );
    }
}

export default Marquee;